
import { RequestPaging } from "@/utils/util";
import { Component, Vue } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import LsPagination from "@/components/ls-pagination.vue";
import PopoverInput from "@/components/popover-input.vue";
import Form from "@/components/form.vue";
import {
  apiColorList,
  apiColorAdd,
  apiColorEdit,
  apiColorDetail,
  apiColorDelete,
} from "@/api/model.ts";
@Component({
  components: {
    LsDialog,
    LsPagination,
    PopoverInput,
    Form,
  },
})
export default class ModelColor extends Vue {
  pager: RequestPaging = new RequestPaging();
  list: any = [];
  searchObj: any = {
    zh_cn_name: "",
    color: "",
  };
  dataForm = {
    id: "",
    zh_cn_name: "",
    en_name: "",
    color: "",
    sort: "1",
  };
  formData = [
    {
      type: "input",
      label: "名称",
      key: "zh_cn_name",
      rules: [{ required: true, message: "请输入名称", trigger: "blur" }],
    },
    {
      type: "input",
      label: "英文名称",
      key: "en_name",
      rules: [{ required: true, message: "请输入英文名称", trigger: "blur" }],
    },
    {
      type: "colorpicker",
      label: "颜色值",
      key: "color",
      rules: [{ required: true, message: "请输入颜色值", trigger: "change" }],
    },
    {
      type: "input",
      label: "排序",
      key: "sort",
    },
  ];
  mode = "add";
  formVisible = false;
  apiColorList = apiColorList;

  handleAdd() {
    this.dataForm = {
      id: "",
      zh_cn_name: "",
      en_name: "",
      color: "",
      sort: "1",
    };
    this.mode = "add";
    this.formVisible = true;
  }

  handleEdit(row: any) {
    this.dataForm = {
      id: row.id,
      zh_cn_name: row.zh_cn_name,
      en_name: row.en_name,
      color: row.color,
      sort: row.sort,
    };
    this.mode = "edit";
    this.formVisible = true;
  }

  handleSubmit(value: object) {
    if (this.mode == "add") {
      apiColorAdd(value).then((res) => {
        this.getList();
        this.formVisible = false;
      });
    } else {
      apiColorEdit(value).then((res) => {
        this.getList();
        this.formVisible = false;
      });
    }
  }

  handleDelete(id: number) {
    apiColorDelete({ id: id }).then((res) => {
      this.getList();
    });
  }

  getList(): void {
    this.pager.request({
      callback: apiColorList,
      params: {
        ...this.searchObj,
      },
    });
  }

  created() {
    this.getList();
  }
}
